<template>
  <div class="users-update page-layout">
    <PageHeader
      title="Changer de mot de passe"
      :has-back="true"
      @back="goBack"
    />

    <!-- ## Les informations de l'utilisateur ## -->
    <md-card class="meep-form">
      <!-- Le titre -->
      <md-card-content>
        <!-- Ancien mot de passe -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('oldPassword') }">
            <label>Ancien mot de passe *</label>
            <md-input
              v-model="form.oldPassword"
              v-validate="'required'"
              name="oldPassword"
              type="password"
            >
            </md-input>
            <span class="md-error" v-show="errors.has('oldPassword')">
              {{ errors.first("oldPassword") }}
            </span>
          </md-field>
        </div>

        <!-- Nouveau mot de passe -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('newPassword') }">
            <label>Nouveau mot de passe *</label>
            <md-input
              v-model="form.newPassword"
              v-validate="{ required: true, min: 6 }"
              name="newPassword"
              type="password"
            >
            </md-input>
            <span class="md-error" v-show="errors.has('newPassword')">
              {{ errors.first("newPassword") }}
            </span>
          </md-field>
        </div>

        <!-- Confirmer nouveau mot de passe -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('confirmNewPassword') }">
            <label>Confirmer nouveau mot de passe *</label>
            <md-input
              v-model="form.confirmNewPassword"
              v-validate="{ required: true, is: form.newPassword }"
              name="confirmNewPassword"
              type="password"
            >
            </md-input>
            <span class="md-error" v-show="errors.has('confirmNewPassword')">
              {{ errors.first("confirmNewPassword") }}
            </span>
          </md-field>
        </div>

        <div class="md-layout md-alignment-center-right">
          <md-button
            class="md-raised md-primary"
            @click.native="onChangePassword"
          >
            Changer de mot de passe
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
/* Modèles */
import superusersModel from "../../../model/superusers";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,

      /* Mot de passe */
      form: {
        oldPassword: "",
        confirmNewPassword: "",
        newPassword: "",
      },
    };
  },
  methods: {
    /* ## EVENEMENTS ## */

    goBack() {
      window.history.back();
    },

    onChangePassword() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });

          return;
        }

        superusersModel
          .changePassword(this.form)
          .then(() => {
            this.$toasted.global.AppSucces({
              message: "Votre mot de passe à bien été mis à jour",
            });

            this.$router.push("/admin/superusers/view/" + this.self.id);
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },
  },
  computed: {
    self() {
      return this.$store.state.self;
    },
  },
};
</script>

<style lang="scss" scoped>
.users-update {
  .debug-log {
    max-height: 420px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

import fetch from '../services/fetch';
import store from '../store';

export default {
	get,
	update,
	changePassword
};

function get(id) {
	return fetch('api/superusers/' + id, {
		method: 'get',
		headers: {'Authorization': store.state.token}
	});
}

function update(superusers) {
	return fetch('api/superusers/' + superusers.id, {
		method: 'put',
		headers: {
			'Authorization': store.state.token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(superusers),
	});
}

function changePassword(form) {
	return fetch('api/superusers/change-password', {
		method: 'post',
		headers: {
			'Authorization': store.state.token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(form),
	});
}
